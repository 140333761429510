import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Button, Row, Col } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BusinessContext } from "../../context/BusinessContext";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";
import moment from "moment";
import Loader from "../Loader/";
import { OrderDetailInfo } from "../../utils/api";

const { Title, Text } = Typography;

const ThankYou = () => {
  const { businessInfo } = useContext(BusinessContext);
  const { orderId } = useParams(); // Get orderId from the URL
  const navigate = useNavigate(); // For redirection
  const [orderInfoData, setOrderInfoData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getOrderDetailInfo = async (orderId) => {
    try {
      const response = await OrderDetailInfo(orderId);
      if (response && response.data) {
        setOrderInfoData(response.data);
      } else {
        console.error("Failed to fetch Order Detail data");
        // Redirect to home if no data
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching Order Detail data:", error);
      // Redirect to home on error
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 3 * 60 * 1000); // 10 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    if (orderId) {
      getOrderDetailInfo(orderId);
    } else {
      navigate("/"); // Redirect to home if orderId is missing
    }
  }, [orderId, navigate]);

  if (loading) {
    return <Loader />;
  }

;
  if (!orderInfoData) {
    navigate(`/`); // Avoid rendering anything if no data
  }
  const formattedOrderDate = moment(orderInfoData.orderDateTime).format(
    "MMMM Do YYYY, h:mm A"
  );
  // Map order type
  const orderType = orderInfoData.orderType === 0 ? "Delivery" : "Pickup";
  return (
    <>
      <div className="menuBanner">
        <img
          src={aboutimg}
          alt="Menu Banner"
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff", margin: "0" }}>
            Thank You for Your Order!
          </Title>
        </div>
      </div>
      <div className="ThankyouSection flex flex-col items-center justify-center min-h-screen bg-green-50 p-6">
  <Row justify="center" align="middle" style={{marginTop:"10px", marginBottom:"10px"}}>
    <Col xs={24} sm={18} md={12} lg={8}>
      <Card className="shadow-lg" style={{ borderRadius: "16px", paddingTop: "4px" }} bordered={false}>
        <div className="text-center">
          {/* Dynamic Order Status Header */}
          <Title level={2} className="text-gray-800 mb-4">
            {orderInfoData.order_status === 0 && "Thank You for Your Order!"}
            {orderInfoData.order_status === 1 && "Your Order is Accepted!"}
            {orderInfoData.order_status === 2 && "Your Order is Being Prepared!"}
            {orderInfoData.order_status === 3 && "Your Order is Ready for Pickup/Delivery!"}
            {orderInfoData.order_status === 4 && "Your Order is On Its Way!"}
            {orderInfoData.order_status === 5 && "Order Completed Successfully!"}
            {orderInfoData.order_status === 6 && "Your Order Has Been Cancelled"}
          </Title>

          {/* Dynamic Status Message */}
          <Text type="secondary" className="text-gray-600 block mb-6">
            {orderInfoData.order_status === 0 &&
              "Your order has been placed successfully. We're reviewing it now."}
            {orderInfoData.order_status === 1 &&
              "Your order has been accepted and will be prepared shortly."}
            {orderInfoData.order_status === 2 &&
              "Our chef is working on your order. It will be ready soon."}
            {orderInfoData.order_status === 3 &&
              "Your order is ready for pickup/delivery. Please collect it or track your delivery partner."}
            {orderInfoData.order_status === 4 &&
              "Your order is on its way! Track the progress on the app."}
            {orderInfoData.order_status === 5 &&
              "We hope you enjoyed your order! Thank you for choosing us."}
            {orderInfoData.order_status === 6 &&
              "Unfortunately, your order has been cancelled. If you have questions, contact support."}
          </Text>

          {/* Order Summary */}
          <div className="flex flex-col items-center my-8">
            <Title level={4} className="text-gray-700 mb-4">Order Summary</Title>
            <div className="bg-gray-100 rounded-md p-4 w-full text-gray-700 shadow-sm">
              <p className="mb-2">
                <strong>Order ID:</strong> {orderInfoData.order_number}
              </p>
              <p className="mb-2">
                <strong>Order Type:</strong> {orderType}
              </p>
              
              {orderInfoData.orderType === 0 && (
              <>
              <p className="mb-2">
                <strong>Order Preparation Time:</strong> {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_estimate_time} Minutes
              </p>
              <p className="mb-2">
                {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_customer_note_en}
              </p>
              </>
              )}

              {orderInfoData.orderType === 1 && (
              <>
               <p className="mb-2">
                <strong>Order Preparation Time:</strong> {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.pickup_estimate_time} Minutes
              </p>
              <p className="mb-2">
                {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.pickup_customer_note_en}
              </p>
              </>
              )}

              <p className="mb-2">
                <strong>Total Amount:</strong> &pound;{orderInfoData.grandTotal}
              </p>
              <p className="mb-2">
                <strong>Order Date & Time:</strong> {formattedOrderDate}
              </p>
              <p className="mb-2">
                <strong>Order Status:</strong>{" "}
                {[
                  "New Order",
                  "Accepted",
                  "Cooking",
                  "Ready",
                  "Delivered",
                  "Completed",
                  "Cancelled",
                ][orderInfoData.order_status]}
              </p>
            </div>
          </div>

          {/* Additional Information */}
          <Text type="secondary" className="block mb-6">
            Track your order on our website or contact us for any questions.
          </Text>

          {/* Buttons with Proper Spacing */}
          <div className="flex justify-center mt-6" style={{marginTop:"10px"}}>
            <Row>
            <Col xs={24} sm={18} md={12}>
          <div className="mx-2">
    {orderInfoData.status !== 6 && (
      <Button
        type="primary"
        className="bg-green-500 text-white hover:bg-green-600 px-6 py-2 rounded"
      >
        <Link
  to={`https://resowner.pdq2.com/download-order-pdf?order_number=${orderInfoData.order_number}`}
  target="_blank"
  download
>
  Download Order 
</Link>

      </Button>
    )}
  </div>
  </Col>
  <Col xs={24} sm={18} md={12}>
            <div className="mx-2">
    <Button
      type="default"
      className="border-green-500 text-green-500 hover:text-green-600 hover:border-green-600 px-6 py-2 rounded"
    >
      <Link to="/">Back to Home</Link>
    </Button>
  </div>
  </Col>
  </Row>
          </div>
        </div>
      </Card>
    </Col>
  </Row>
</div>

    </>
  );
};

export default ThankYou;
