let map;
let marker;
let geocoder;
let infoWindow;

export function initMap(updateMapData) {
  const mapDiv = document.getElementById("map");
  if (!mapDiv) {
    console.error("Map div not found!");
    return;
  }

  map = new google.maps.Map(mapDiv, {
    zoom: 15,
    center: { lat: 0, lng: 0 }, // Default center before geolocation
    gestureHandling: 'greedy',
  });

  geocoder = new google.maps.Geocoder();
  infoWindow = new google.maps.InfoWindow();

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        map.setCenter(pos);

        marker = new google.maps.Marker({
          position: pos,
          map: map,
          draggable: true,
        });

        geocodePosition(marker.getPosition(), updateMapData);

        google.maps.event.addListener(marker, "dragend", function () {
          geocodePosition(marker.getPosition(), updateMapData);
        });
      },
      () => {
        handleLocationError(true, map.getCenter());
      }
    );
  } else {
    handleLocationError(false, map.getCenter());
  }

  const input = document.getElementById("pac-input");
  const searchBox = new google.maps.places.SearchBox(input);

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds());
  });

  searchBox.addListener("places_changed", () => {
    const places = searchBox.getPlaces();

    if (places.length === 0) {
      return;
    }

    if (marker) {
      marker.setMap(null);
    }

    const bounds = new google.maps.LatLngBounds();
    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      marker = new google.maps.Marker({
        map: map,
        title: place.name,
        position: place.geometry.location,
        draggable: true,
      });

      geocodePosition(marker.getPosition(), updateMapData);

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      marker.addListener("dragend", function () {
        geocodePosition(marker.getPosition(), updateMapData);
      });
    });
    map.fitBounds(bounds);
  });
}

function geocodePosition(pos, updateMapData) {
  geocoder.geocode({ latLng: pos }, function (results, status) {
    if (status === google.maps.GeocoderStatus.OK) {
      const address = results[0].formatted_address;
      const addressComponents = results[0].address_components;

      let houseNumberAndStreet = "";
      let locality = "";
      let country = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
          houseNumberAndStreet += component.long_name + " ";
        }
        if (component.types.includes("route")) {
          houseNumberAndStreet += component.long_name;
        }
        if (component.types.includes("locality") || component.types.includes("postal_town")) {
          locality = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
      });

      const zipCode = extractZipCode(results[0].address_components);
      updateAddressList(address, pos.lat(), pos.lng(), zipCode, houseNumberAndStreet, locality, country);
      updateMapData(address, pos.lat(), pos.lng(), zipCode, houseNumberAndStreet, locality, country);

      infoWindow.setContent(address + (zipCode ? ` (ZIP: ${zipCode})` : ""));
      infoWindow.open(map, marker);
    } else {
      console.log("Geocode failed: " + status);
    }
  });
}

function extractZipCode(addressComponents) {
  for (const component of addressComponents) {
    if (component.types.includes("postal_code")) {
      return component.long_name;
    }
  }
  return null;
}

function updateAddressList(address, lat, lng, zipCode, houseNumberAndStreet, locality, country) {
  
  if (address) {
  document.getElementById("google_full_address").value = address ?? '';
  }
  if (lat) {
  document.getElementById("latitude").value = lat ?? '';
  }
  if (lng) {
  document.getElementById("longitude").value = lng ?? '';
  }
  if (houseNumberAndStreet) {
    document.getElementById("house-number-street").value = houseNumberAndStreet;
  }
  if (locality) {
    document.getElementById("locality").value = locality;
  }
  if (country) {
    document.getElementById("country").value = country;
  }
  if (zipCode) {
    document.getElementById("zipcode").value = zipCode;
  }
}

function handleLocationError(browserHasGeolocation, pos) {
  console.error(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  map.setCenter(pos);
}
