// src/utils/api.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY,
  },
});

export const fetchBusinessInfo = async () => {
  try {
    const response = await api.get("/resbusiness/info");

    // Check if the response contains the businessInfo
    if (response.data && response.data.businessInfo) {
      return response.data; // Return the businessInfo array
    } else {
      console.error("No businessInfo found in the response.");
      return null; // Return null if businessInfo is missing
    }
  } catch (error) {
    console.error("Error fetching business info:", error);
    throw error;
  }
};

export const fetchResNotice = async () => {
  try {
    const response = await api.get("/resbusiness/notice");
    return response.data;
  } catch (error) {
    console.error("Error fetching restaurant notice:", error);
    return null;
  }
};

export const fetchResServices = async () => {
  try {
    const response = await api.get("/resbusiness/services");
    return response.data;
  } catch (error) {
    console.error("Error fetching restaurant services:", error);
    return null;
  }
};

export const fetchPopularCate = async () => {
  try {
    const response = await api.get("/resbusiness/popular/category");
    return response.data;
  } catch (error) {
    console.error("Error fetching popular categories:", error);
    return null;
  }
};

export const fetchCategoriesAndFoodItems = async () => {
  try {
    const response = await api.get("/resbusiness/food/items");
    return response.data;
  } catch (error) {
    console.error("Error fetching categories and food items:", error);
    return null;
  }
};

export const fetchaboutUs = async () => {
  try {
    const response = await api.get("/res/about");
    return response.data;
  } catch (error) {
    console.error("Error fetching about us:", error);
    return null;
  }
};

export const fetchAllergyNutritional = async () => {
  try {
    const response = await api.get("/res/nutritional-allerg");
    return response.data;
  } catch (error) {
    console.error("Error fetching nutritional:", error);
    return null;
  }
};

export const fetchTerms = async () => {
  try {
    const response = await api.get("/res/terms");
    return response.data;
  } catch (error) {
    console.error("Error fetching terms:", error);
    return null;
  }
};

export const fetchPolicy = async () => {
  try {
    const response = await api.get("/res/policy");
    return response.data;
  } catch (error) {
    console.error("Error fetching policy:", error);
    return null;
  }
};

export const fetchFAQs = async () => {
  try {
    const response = await api.get("/res/faqs");
    return response.data;
  } catch (error) {
    console.error("Error fetching nutritional:", error);
    return null;
  }
};

export const SubmitReservation = async (values) => {
  try {
    const response = await api.post("/res/reservations", values, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting reservation data:", error);
    return null; // Returning null to indicate a failure
  }
};


export const fetchUserAddress = async (user_id) => {
  if (!user_id) {
    console.error("User ID is required to fetch addresses.");
    return null;
  }
  try {
    const response = await api.get(`/user/address/${user_id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching addresses for user_id ${user_id}:`, error);
    return null;
  }
};


export const CheckDeliveryUserAddress = async (formdata) => {
  try {
    const response = await api.post("/check-user-delivery-address", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error checking delivery address:", error);
      throw error; 
    }
  }
};

export const CheckDeliveryGuestAddress = async (formdata) => {
  try {
    const response = await api.post("/check-guest-delivery-address", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error checking delivery address:", error);
      throw error; 
    }
  }
};

export const AddUserAddress = async (formdata) => {
  try {
    const response = await api.post("/user/add-address", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error adding address:", error);
      throw error; 
    }
  }
};

export const fetchGuestUserAddress = async (cookiesId) => {
  if (!cookiesId) {
    console.error("User ID is required to fetch addresses.");
    return null;
  }
  try {
    const response = await api.get(`/guest/user/address-list/${cookiesId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching addresses for cookiesId ${cookiesId}:`, error);
    return null;
  }
};

export const AddGuestUserAddress = async (formdata) => {
  try {
    const response = await api.post("/guest/user/add-address", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error adding address:", error);
      throw error; 
    }
  }
};


export const deleteGuestUserAddress = async (addressId) => {
  if (!addressId) {
    console.error("Address ID is required to delete an address.");
    return null;
  }
  try {
    const response = await api.get(`/guest/user/delete-address`, { params: { address_id: addressId } });
    return response.data; 
    
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error(`Error deleting address ID ${addressId}:`, error);
      throw error; 
    }
  }
};



export const userSignUp = async (formData) => {
  try {
    const response = await api.post("/user/sign-up", formData);
    return response.data; 
  } catch (error) {
    console.error("Error during user sign-up:", error.message);
    if (error.response) {
      return error.response.data; 
    }
    throw error; 
  }
};

export const userSignIn = async (formData) => {
  try {
    const response = await api.post("/user/sign-in", formData);
    return response.data; 
  } catch (error) {
    console.error("Error during user sign-in:", error.message);
    if (error.response) {
      return error.response.data; 
    }
    throw error; 
  }
};


export const handleOtpVerification = async (formData) => {
  try {
    const response = await api.post("/user/sign-up-otp", formData);
    return response.data; 
  } catch (error) {
    console.error("Error during user sign-up-otp:", error.message);
    if (error.response) {
      return error.response.data; 
    }
    throw error; 
  }
};

export const OrderPaymentSubmit = async (formdata) => {
  try {
    const response = await api.post("/payment-proccess-api", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error adding address:", error);
      throw error; 
    }
  }
};

export const StripePaymentSubmit = async (formdata) => {
  try {
    const response = await api.post("/stripe/process-payment", formdata);
    return response.data; 
  } catch (error) {
    if (error.response) {
      return error.response.data; 
    } else {
      console.error("Error adding address:", error);
      throw error; 
    }
  }
};

export const OrderDetailInfo = async (orderNumber) => {
  if (!orderNumber) {
    console.error("Order ID is required to fetch addresses.");
    return null;
  }
  try {
    const response = await api.get(`/order-details/${orderNumber}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching orders for cookiesId ${orderNumber}:`, error);
    return null;
  }
};

export default api;
