import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Collapse } from "antd";
import "../../assets/css/Font.css";
import parse from "html-react-parser";
import Loader from "../Loader/";
import { fetchFAQs } from "../../utils/api";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const FAQs = () => {
    const [cmsData, setCMSData] = useState(null); // Single object to hold CMS data
    const [loading, setLoading] = useState(true);

    // Function to decode base64 encoded text
    const decodeBase64 = (encodedText) => {
        try {
            return atob(encodedText); // Decode the base64 string
        } catch (e) {
            console.error("Error decoding base64:", e);
            return encodedText; // Return original if decoding fails
        }
    };

    // Fetch CMS data
    const getCmsInfo = async () => {
        try {
            const fetchedData = await fetchFAQs();
            if (fetchedData) {
                console.log("fetchedData", fetchedData);
                setCMSData(fetchedData.data); // Assuming AllergyNutritional returns an array
            } else {
                console.error("Failed to fetch CMS data");
            }
        } catch (error) {
            console.error("Error fetching CMS data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCmsInfo();
    }, []);

    // Show loading state if data is not yet fetched
    if (loading) {
        return <Loader />;
    }

    // Check if cmsData exists
    if (!cmsData) {
        return (
            <div className="custom_container">
                <Title level={4} className="textCenter">
                    No data available
                </Title>
            </div>
        );
    }

    return (
        <>
            {/* Banner Section */}
            <div className="menuBanner">
                <img
                    src="https://resowner.pdq2.com/res_logos/galleries/6745f6568eb99-concept-delicious-food-passion-fruit-mousse185193-104849.jpg" // Fallback image if no banner is provided
                    alt="Banner"
                    style={{ width: "100%", height: "235px", objectFit: "cover" }}
                />
                <div className="innerBannerTitle">
                    <Title level={2} style={{ color: "#fff", margin: "0" }}>
                        FAQs
                    </Title>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="custom_container">
                <Row gutter={32} className="innerCommonWrap">
                    <Col span={24}>

                        {/* FAQ Collapse Section */}
                        <Collapse accordion>
                            {cmsData && cmsData.length > 0 ? (
                                cmsData.map((faq, index) => (
                                    <Panel header={<span style={{ fontWeight: 'bold', fontSize:"16px" }}>{faq.question_txt_en}</span>} key={index}>
                                        <Paragraph>
                                            {parse(decodeBase64(faq.answer_text_en))}
                                        </Paragraph>
                                    </Panel>
                                ))
                            ) : (
                                <Paragraph>No FAQs available at the moment.</Paragraph>
                            )}
                        </Collapse>

                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FAQs;
