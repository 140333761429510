// src/router/AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MyHeader from "../components/layouts/Header";
import MyFooter from "../components/layouts/Footer";
import Home from "../components/pages/Home";
import Order from "../components/pages/Order";
import Menu from "../components/pages/Menu";
import ThankYou from "../components/pages/ThankYou";
import ContactUs from "../components/pages/ContactUs";
import Privacy from "../components/pages/Privacy";
import TermsConditions from "../components/pages/TermsConditions";
import AboutUs from "../components/pages/AboutUs";
import FAQs from "../components/pages/FAQs";
import AllergyNutritional from "../components/pages/AllergyNutritional";
import ResGallery from "../components/pages/ResGallery";
import Reservation from "../components/pages/Reservation";
import Offers from "../components/pages/Offers";

const AppRouter = () => {
  return (
    <Router>
      <MyHeader />
      <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<Order />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/thank-you/:orderId" element={<ThankYou />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/nutrition-allergy" element={<AllergyNutritional />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/gallery" element={<ResGallery />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/our-offer" element={<Offers />} />
      </Routes>
      <MyFooter />
    </Router>
  );
};

export default AppRouter;
